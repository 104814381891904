.page-container {
  display: flex;
  flex-direction: row;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0 30px;
}

.container .container {
  padding: 0;
}

.container.space-around {
  justify-content: space-around;
}

.container.center {
  justify-content: center !important;
}

.container.row {
  flex-direction: row;
}

.col-12 {
  width: 100%;
}

.col-11 {
  width: 88%;
}

.col-10 {
  width: 80%;
}

.col-9 {
  width: 72%;
}

.col-8 {
  width: 64%;
}

.col-7 {
  width: 56%;
}

.col-6 {
  width: 48%;
}

.col-5 {
  width: 40%;
}

.col-4 {
  width: 32%;
}

.col-3 {
  width: 24%;
}

.col-2 {
  width: 16%;
}

.col-1 {
  width: 8%;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.section-header h2 {
  margin: 0;
}

.section-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.section-actions>.actions-dropdown:not(:last-child),
.section-actions>.btn-primary:not(:last-child),
.section-actions>.btn-secondary:not(:last-child),
.section-actions>.btn-tertiary:not(:last-child),
.section-actions>a:not(:last-child) {
  margin-right: 24px;
}

.section-body {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.section-body.center {
  justify-content: center !important;
}

.section-body .card {
  margin-bottom: 40px;
}

.card {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 5%) 1px 2px 2px;
  border: 2px solid rgb(238, 238, 238);
}

.card-header {
  padding: 5px 30px 0px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.card-body {
  padding: 0 30px 5px 30px;
}

.card-body.no-footer {
  padding-bottom: 30px;
  padding-top: 15px;
}

.card-footer {
  padding: 5px 30px 15px 30px;
}

span.label {
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 5px;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.badge {
  background-color: #1865f2;
  color: #ffffff;
  padding: 8px 12px;
  margin-left: 10px;
  border-radius: 24px;
  font-size: 12px;
  text-transform: uppercase;
}