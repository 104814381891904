.progress-bar-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.progress-bar-container {
  border: 1px solid rgb(238, 238, 238);
  border-radius: 18px;
  display: inline-block;
  flex-grow: 1;
  height: 18px;
  min-width: 100px;
  margin: 0 16px;
}

.progress-bar {
  background-color: #9059ff;
  border-radius: 18px;
  height: 18px;
}

.progress-bar-info {
  display: inline-block;
}

.progress-bar-info span {
  color: #9059ff;
}