.actions-container button:not(:first-of-type) {
  margin-left: 14px;
}

.col-10 {
  margin: auto;
}

.col-10:last-of-type {
  margin-top: 40px;
  margin-bottom: 100px;
}