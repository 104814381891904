.modal-background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: rgba(33, 36, 44, 0.64) !important;
  z-index: 400;
}

.modal-container {
  background-color: #ffffff;
  border-radius: 5px;
  min-width: 400px;
  z-index: 401;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 20px;
}

.modal-header h2 {
  margin: 0;
}

.modal-container .form {
  display: block !important;
}

.modal-body,
.modal-container .form-fields-wrapper {
  padding: 30px 20px;
  max-height: 40vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.modal-container .form-fields-wrapper {
  padding: 30px 20px 100px 20px;
  margin: 0;
}

.modal-footer,
.modal-container .form-actions {
  border-top: 1px solid #ddd;
  padding: 20px;
}

.modal-footer .btn-tertiary,
.form-actions .btn-tertiary {
  margin-right: 16px;
}

.close-modal {
  background-color: inherit;
  border: none !important;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.close-modal:hover {
  color: #1865f2;
  border: 2px solid #1865f2 !important;
  cursor: pointer;
}

.close-modal:active {
  color: #1b50b3;
  border: 2px solid #1b50b3 !important;
  cursor: pointer;
}

.confirm-modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}