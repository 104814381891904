.btn-primary {
  background-color: #1865f2 !important;
  color: #ffffff !important;
  padding: 10px 16px !important;
  border-radius: 4px;
  border: none !important;
  font-size: 16px;
  cursor: pointer !important;
  text-decoration: none !important;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 72px;
}

.btn-primary:hover {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #1865f2 !important;
  text-decoration: none !important;
}

.btn-primary:active {
  background-color: #1b50b3 !important;
  color: #b5cefb !important;
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #1b50b3 !important;
  text-decoration: none !important;
}

.btn-primary:disabled {
  background: rgba(33, 36, 44, 0.32) !important;
  color: rgba(255, 255, 255, 0.64) !important;
  cursor: default !important;
}

.btn-primary:disabled:hover {
  box-shadow: none !important;
}

.btn-primary .loading-spinner,
.btn-destructive-primary .loading-spinner {
  width: 24px;
  height: 24px;
  position: absolute;
}

.btn-primary .loading-spinner path,
.btn-destructive-primary .loading-spinner path {
  fill: #ffffff;
}

.btn-secondary {
  color: #1865f2 !important;
  border: 1px solid rgba(33, 36, 44, 0.50) !important;
  background-color: #ffffff;
  padding: 10px 16px !important;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer !important;
  text-decoration: none !important;
  height: 40px !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn-secondary:hover {
  border: 2px solid #1865f2 !important;
  text-decoration: none !important;
}

.btn-secondary:active {
  background-color: #b5cefb !important;
  color: #1b50b3 !important;
  border-color: #1b50b3 !important;
}

.btn-secondary:disabled {
  color: rgba(33, 36, 44, 0.32) !important;
  border-color: rgba(33, 36, 44, 0.32) !important;
  cursor: default !important;
}

.btn-secondary:disabled:hover {
  border: 1px solid rgba(33, 36, 44, 0.32) !important;
}

.btn-secondary:disabled:active {
  background-color: #ffffff !important;
  color: rgba(33, 36, 44, 0.32) !important;
  border-color: rgba(33, 36, 44, 0.32) !important;
}

.btn-tertiary {
  color: #1865f2 !important;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer !important;
  font-weight: 400 !important;
  text-decoration: none !important;
  position: relative !important;
  background-color: inherit !important;
  border: none !important;
  padding: 0;
}

.btn-tertiary:hover::after {
  content: '' !important;
  position: absolute !important;
  height: 2px !important;
  width: calc(100% - 0px) !important;
  right: 0px !important;
  bottom: 0px !important;
  background: #1865f2 !important;
  border-radius: 2px !important;
}

.btn-tertiary:active {
  color: #1b50b3 !important;
}

.btn-tertiary:active::after {
  content: '' !important;
  position: absolute !important;
  height: 2px !important;
  width: calc(100% - 0px) !important;
  right: 0px !important;
  bottom: calc(50% - 11px) !important;
  background: #1b50b3 !important;
  border-radius: 2px !important;
}

.btn-tertiary:disabled {
  color: rgba(33, 36, 44, 0.32) !important;
  cursor: default !important;
}

.btn-tertiary:disabled:hover::after {
  height: 0px !important;
}

.btn-tertiary-inverse {
  color: #ffffff !important;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer !important;
  font-weight: 400 !important;
  text-decoration: none !important;
  position: relative !important;
  border: none;
  background: none;
}

.btn-tertiary-inverse:hover::after {
  content: '' !important;
  position: absolute !important;
  height: 2px !important;
  width: calc(100% - 0px) !important;
  right: 0px !important;
  bottom: 0px !important;
  background: #ffffff !important;
  border-radius: 2px !important;
}

.btn-tertiary-inverse:active {
  color: #bcbcbc !important;
}

.btn-tertiary-inverse:active::after {
  content: '' !important;
  position: absolute !important;
  height: 2px !important;
  width: calc(100% - 0px) !important;
  right: 0px !important;
  bottom: calc(50% - 11px) !important;
  background: #bcbcbc !important;
  border-radius: 2px !important;
}

.btn-destructive-primary {
  background-color: #d92916 !important;
  color: #ffffff !important;
  padding: 10px 16px !important;
  border-radius: 4px;
  border: none !important;
  font-size: 16px;
  cursor: pointer !important;
  text-decoration: none;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn-destructive-primary:hover {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #d92916 !important;
  text-decoration: none;
}

.btn-destructive-primary:active {
  background-color: #9e271d !important;
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #9e271d !important;
  color: #f3bbb4 !important;
}

.btn-destructive-secondary {
  color: #d92916 !important;
  background-color: #ffffff;
  border: 1px solid #d92916 !important;
  padding: 10px 16px !important;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer !important;
  text-decoration: none;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn-destructive-secondary:hover {
  border: 2px solid #d92916 !important;
  padding: 10px 15px !important;
  text-decoration: none;
}

.btn-destructive-secondary:active {
  background-color: #f3bbb4 !important;
  color: #9e271d !important;
  border: 2px solid #9e271d !important;
}

.btn-destructive-tertiary {
  color: #d92916 !important;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer !important;
  font-weight: 400 !important;
  text-decoration: none !important;
  position: relative !important;
  background-color: inherit !important;
  border: none !important;
  padding: 0;
}

.btn-destructive-tertiary:hover::after {
  content: '' !important;
  position: absolute !important;
  height: 2px !important;
  width: calc(100% - 0px) !important;
  right: 0px !important;
  bottom: 0px !important;
  background: #d92916 !important;
  border-radius: 2px !important;
}

.btn-destructive-tertiary:active {
  color: #9e271d !important;
}

.btn-destructive-tertiary:active::after {
  content: '' !important;
  position: absolute !important;
  height: 2px !important;
  width: calc(100% - 0px) !important;
  right: 0px !important;
  bottom: calc(50% - 11px) !important;
  background: #9e271d !important;
  border-radius: 2px !important;
}

.btn-destructive-tertiary:disabled {
  color: rgba(33, 36, 44, 0.32) !important;
  cursor: default !important;
}

.btn-destructive-tertiary:disabled:hover::after {
  height: 0px !important;
}