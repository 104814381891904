header {
  box-sizing: border-box;
}

header nav {
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: #0a2a66;
  color: #ffffff;
}

.logo-container {
  display: flex;
  align-items: center;
  height: 100%;
  background-color: inherit;
}

.logo-container a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.logo-container a svg {
  width: 35px;
  height: 35px;
  padding: 0 10px 0 16px;
}

header .left {
  flex-grow: 1;
}

.logo:hover {
  cursor: pointer;
  text-decoration: none !important;
}

.links span a {
  color: inherit;
  text-decoration: none;
  margin-left: 20px;
  cursor: pointer;
  font-weight: 700;
}

.links span a:hover {
  text-decoration: underline;
}

.banner {
  padding: 15px 30px;
  background-color: #1865f2;
  color: #ffffff;
}

.banner .btn-tertiary-inverse {
  float: right;
}

.banner .btn-primary:hover {
  border: none !important;
  box-shadow: none !important;
  text-decoration: underline !important;
}

.banner svg {
  width: 20px;
  height: 20px;
  position: absolute;
}

.banner.alert .alert-container {
  margin-left: 30px;
}

.banner .alert-type {
  font-weight: bold;
}

.banner .alert-message {
  margin-left: 5px;
}