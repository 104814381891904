.table-card {
  box-sizing: border-box;
  padding: 20px;
}

.table-card h3 {
  margin-top: 10px;
  padding-left: 8px;
}

table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
}

tr {
  border-bottom: 1px solid rgba(33, 36, 44, 0.16);
}

td,
th {
  padding: 16px 8px;
}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
}