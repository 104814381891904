.article-header {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.article-content {
  padding: 20px 20px 20px 30px;
}

.article-content h2 {
  font-size: 20px;
}

.article-content a {
  word-break: break-word;
  color: #1865f2 !important;
}

.article-content h2:not(:first-of-type) {
  margin-top: 40px;
}

.article-footer {
  font-size: 12px;
  text-align: right;
}

.article-content img {
  max-width: 100%;
}